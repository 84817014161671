/**
 * create by pei 2019/03/12
 */
$(function(){
    $("#navMenu li").eq(0).addClass("nav-active");
    $(".menutips li").css("background","#10062C");
    $('#downBox').fullpage({
        sectionsColor: ['#ffffff', '#F5F5F5', '#FFFFFF', '#000000'],
        'navigation': true,
        autoScrolling: false
    });
    $(".downArrow").click(function(){
       $("#fp-nav ul li").eq(1).find("a").click();
    })
    // 展开更多
    $(".more").click(function(){
        $(".moreAdvertisingMedia").css("display","block");
        $(".more").css("display","none");
    })
    $(".advertisingResourcesContent .advertisingMedia .advertisingMediaRight").each(function(index,item){
        endTimeControl($(item).find("input").eq(0),$(item).find("input").eq(1),$(item).find("input").eq(2))
        $(item).find(".immediateSales").click(function(){
            var area=$(this).attr("data-area");
            var start=$(item).find("input").eq(0).val();
            var end=$(item).find("input").eq(1).val();
            var total=$(item).find("input").eq(2).val();
            if(start&&end){
                window.open("./advertisingMedia.html?start="+start+"&end="+end+"&area="+area+"&total="+total,'_self')
            }
        })
    })
    $(".startCustomizationBtn").click(function(){
        window.location.href="advertisingMedia.html"
    })
})